import React from 'react';
import './Cart.css';

const Cart = ({ cart, onCheckout, onContinueShopping, onRemoveFromCart }) => {
  const USD_TO_FANON = 100;

  const calculateTotalAmountInFanon = () => {
    return cart.reduce((total, item) => total + item.price, 0);
  };

  const calculateTotalAmountInUSD = () => {
    const totalFanon = calculateTotalAmountInFanon();
    return (totalFanon / USD_TO_FANON).toFixed(2);
  };

  const generateDownloadLink = (bookId) => {
    return `http://localhost:4000/download/${bookId}.pdf`;
  };

  const handleCheckout = () => {
    const totalAmountFanon = calculateTotalAmountInFanon();

    // Generate download links for all items in the cart
    const downloadLinks = cart.map(item => ({
      title: item.title,
      link: generateDownloadLink(item._id),
    }));

    sessionStorage.setItem('downloadLinks', JSON.stringify(downloadLinks));

    // Redirect to PaymentForm with the amount parameter
    window.location.href = `/payment-form?amount=${totalAmountFanon}`; // Pass amount as query parameter
  };

  return (
    <div className="cart">
      <h2 className="h2">YOUR CART</h2>
      {cart.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <>
          <ul>
            {cart.map((item, index) => (
              <li key={index} className="cart-item">
                {item.imagePath && (
                  <img
                    src={`https://www.panafricanbookexchange-wandererspath.com/${item.imagePath}`}
                    alt={item.title}
                    className="cart-item-image"
                  />
                )}
                <div className="cart-item-details">
                  <h4>{item.title} by {item.author}</h4>
                  <p>Price: {item.price} Fanon (USD {(item.price / USD_TO_FANON).toFixed(2)})</p>
                </div>
                <button
                  className="remove-btn"
                  onClick={() => onRemoveFromCart(item._id)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
          <div className="cart-total">
            <h3>Total Amount: {calculateTotalAmountInFanon()} Fanon (USD {calculateTotalAmountInUSD()})</h3>
          </div>
        </>
      )}
      <button onClick={handleCheckout} className="continue-shopping-btn checkout">Checkout</button>
      <button onClick={onContinueShopping} className="continue-shopping-btn">Continue Shopping</button>

    </div>
  );
};

export default Cart;



