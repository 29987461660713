
import React, { useEffect, useState } from 'react';
import './PaymentForm.css'; // Import the CSS file

const PaymentForm = () => {
  const [amount, setAmount] = useState('');
  const [downloadLinks, setDownloadLinks] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setAmount(urlParams.get('amount') || '');
    const linksParam = urlParams.get('downloadLinks');
    setDownloadLinks(linksParam ? JSON.parse(decodeURIComponent(linksParam)) : []);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!amount || !downloadLinks.length) {
    //   alert("Please provide both the amount and download links.");
    //   return;
    // }

    // Generate a DRM token for secure download
    const token = generateDRMToken(amount);

    const actionUrl = `http://195.35.48.252:8000/pesa/index.php?amount=${encodeURIComponent(amount)}&downloadLinks=${encodeURIComponent(JSON.stringify(downloadLinks))}&token=${encodeURIComponent(token)}`;

    // Redirect to the payment gateway with the necessary parameters
    window.location.href = actionUrl;
  };

  const generateDRMToken = (amount) => {
    const timestamp = new Date().getTime();
    return btoa(`${amount}:${timestamp}`);
  };

  return (
    <div>
      <h2 className="payment-heading">Payment Details</h2> {/* Apply the CSS class */}
      {amount && <p>Amount: {amount} Fanon</p>}
      <form onSubmit={handleSubmit}>
        <button type="submit">Proceed to Payment</button>
      </form>
    </div>
  );
};

export default PaymentForm;

