



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './HomePage.css';
import BookGraph from './BookGraph2'; 
import { io } from 'socket.io-client';
import Login from './Login';
import Register from './Register';
import Slideshow from './Slideshow';
import Founder from './Founder'; // Import Founder component
import { Link } from 'react-router-dom';


const HomePage = () => {
  const [topBooks, setTopBooks] = useState([]);
  const [bookDataForChart, setBookDataForChart] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  // States for handling modals
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [isFounderOpen, setIsFounderOpen] = useState(false);

  // Fetch books and set up socket connection
  useEffect(() => {
    const fetchTopBooks = async () => {
      try {
        //const response = await axios.get('https://www.panafricanbookexchange-wandererspath.com/api/books');

        const API_URL = process.env.REACT_APP_API_URL || 'https://www.panafricanbookexchange-wandererspath.com/api/books';
        const response = await axios.get(API_URL);     



        const sortedBooks = response.data.sort((a, b) => b.engagements - a.engagements).slice(0, 5);
        setTopBooks(sortedBooks);

        const chartData = sortedBooks.map((book) => ({
          title: book.title,
          engagements: book.engagements,
        }));
        setBookDataForChart(chartData);
      } catch (error) {
        console.error('Error fetching books:', error);
      }
    };

    fetchTopBooks();

    const socket = io('https://www.panafricanbookexchange-wandererspath.com');
    
    socket.on('bookPriceUpdate', (updatedBook) => {
      setTopBooks((prevBooks) =>
        prevBooks.map((book) => (book._id === updatedBook._id ? updatedBook : book))
      );
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // Function to handle image click and display modal
  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath);
  };

  // Modal handlers
  const handleLoginOpen = () => setIsLoginOpen(true);
  const handleLoginClose = () => setIsLoginOpen(false);
  const handleRegisterOpen = () => setIsRegisterOpen(true);
  const handleRegisterClose = () => setIsRegisterOpen(false);
  const handleFounderOpen = () => setIsFounderOpen(true);
  const handleFounderClose = () => setIsFounderOpen(false);

  return (
    <div className="home-page-container">
      <nav className="navbar">
          <button className="buttonLogin" onClick={handleLoginOpen}>Login</button>
          <button className="buttonRegister" onClick={handleRegisterOpen}>Register</button>
          <button className="founder-button" onClick={handleFounderOpen}>About the Founder</button>
          <Link to="/admin">
            <button className="author-button">Author</button> {/* Author button */}
          </Link>
        </nav>

        {/* Login Modal */}
        {isLoginOpen && (
          <div className="modal" onClick={handleLoginClose}>
            <div className="modal-content login-modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="close-btn" onClick={handleLoginClose}>&times;</span>
              <Login />
            </div>
          </div>
        )}

        {/* Register Modal */}
        {isRegisterOpen && (
          <div className="modal" onClick={handleFounderClose}>
            <div className="modal-content register-modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="close-btn" onClick={handleRegisterClose}>&times;</span>
              <Register />
            </div>
          </div>
        )}

        {/* Founder Modal */}
        {isFounderOpen && (
          <div className="modal" onClick={handleFounderClose}>
            <div className="modal-content founder-modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="close-btn" onClick={handleFounderClose}>&times;</span>
              <Founder />
            </div>
          </div>
        )}
<br></br>

      <Slideshow />
      

      {/* Top Books Section */}
      <h2 className="home-page-title">Top 5 Engaged Books</h2>

      {topBooks.length === 0 ? (
        <p>No books available</p>
      ) : (
        <ul className="top-book-list">
          {topBooks.map((book) => (
            <li className="top-book-item" key={book._id}>
              <div className="top-book-details">
                {book.imagePath && (
                  <img
                    src={`https://www.panafricanbookexchange-wandererspath.com/${book.imagePath}`} 
                    alt={`${book.title} cover`}
                    className="top-book-image"
                    onClick={() => handleImageClick(book.imagePath)}
                  />
                )}
                <h3>{book.title}</h3>
                <p>By: {book.author}</p>
                <p>Price: {book.price} Fanon</p>
                <p>Engagements: {book.engagements}</p>
              </div>
            </li>
          ))}
        </ul>
      )}

      {/* Engagement Graph Section */}
      {bookDataForChart.length > 0 && (
        <div className="chart-container">
          <h3>Engagement Levels</h3>
          <BookGraph data={bookDataForChart} />
        </div>
      )}

      {/* Image Modal */}
      {selectedImage && (
        <div className="image-modal" onClick={() => setSelectedImage(null)}>
          <div className="image-modal-content">
            <span className="close-btn" onClick={() => setSelectedImage(null)}>&times;</span>
            <img
              src={`https://www.panafricanbookexchange-wandererspath.com/${selectedImage}`}
              alt="Enlarged book cover"
              className="modal-image"
            />
          </div>
        </div>
      )}

      {/* Login Modal */}
     <Login isOpen={isLoginOpen} onClose={handleLoginClose} />

 {/* Register Modal */}
 <Register isOpen={isRegisterOpen} onClose={handleRegisterClose} />

      
    </div>
  );
};

export default HomePage;

