import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddArtForm.css';

const AddArtForm = () => {
  const [title, setTitle] = useState('');
  const [artist, setArtist] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(null);
  const [arts, setArts] = useState([]);
  const [editingArtId, setEditingArtId] = useState(null);

  useEffect(() => {
    const fetchArts = async () => {
      try {
        const response = await axios.get('http://195.35.48.252:4000/api/arts');
        setArts(response.data);
      } catch (error) {
        console.error('Error fetching arts:', error);
      }
    };

    fetchArts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('artist', artist);
    formData.append('price', price);
    if (image) formData.append('image', image);

    try {
      if (editingArtId) {
        await axios.put(`http://195.35.48.252:4000/api/arts/${editingArtId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setEditingArtId(null);
      } else {
        await axios.post('http://195.35.48.252:4000/api/arts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      // Refresh the list of arts
      const response = await axios.get('http://195.35.48.252:4000/api/arts');
      setArts(response.data);

      // Reset form fields
      setTitle('');
      setArtist('');
      setPrice('');
      setImage(null);
    } catch (error) {
      console.error('Error submitting art:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://195.35.48.252:4000/api/arts/${id}`);
      setArts(arts.filter(art => art._id !== id));
    } catch (error) {
      console.error('Error deleting art:', error);
    }
  };

  const handleEdit = (art) => {
    setTitle(art.title);
    setArtist(art.artist);
    setPrice(art.price);
    setEditingArtId(art._id);
  };


  return (
    <div className="art-form-container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Artist Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Artist Name"
          value={artist}
          onChange={(e) => setArtist(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />
        <input
          type="file"
          accept=".pdf, .jpeg, .jpg, .png"
          onChange={(e) => setImage(e.target.files[0])}
        />
        <button type="submit">
          {editingArtId ? 'Update Art' : 'Add Art'}
        </button>
      </form>

      <div className="art-list">
        {arts.map(art => (
          <div key={art._id} className="art-item">
             {art.imagePath && (
              <img
                src={`http://195.35.48.252:4000${art.imagePath}`}
                alt={art.title}
              />
            )}
            <h3>{art.title}</h3>
            <p>Artist: {art.artist}</p>
            <p>Price: {art.price}</p>
           
            <br />
            <button  onClick={() => handleEdit(art)}>Edit</button>
            <button className='buttonA' onClick={() => handleDelete(art._id)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddArtForm;
