import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const DownloadPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [downloads, setDownloads] = useState(location.state?.cart || []);

  const handleDownload = (bookId, downloadUrl) => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `${bookId}.pdf`; // Assuming the file is a PDF
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Remove the download link after clicking (simulate expiration)
    setDownloads((prevDownloads) =>
      prevDownloads.filter((item) => item._id !== bookId)
    );
  };

  return (
    <div className="download-page">
      <h1>Your Downloads</h1>
      {downloads.length > 0 ? (
        <ul>
          {downloads.map((book) => (
            <li key={book._id}>
              <p>
                <strong>{book.title}</strong>
              </p>
              <button
                className="download-btn"
                onClick={() => handleDownload(book._id, book.downloadUrl)}
              >
                Download
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No downloads available.</p>
      )}
         </div>
  );
};

export default DownloadPage;
